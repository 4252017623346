import { Link } from  'react-router-dom'

import ContentPage from '../../content/contentPage'

interface ArchiveButtonsProps {
    contentPage: ContentPage,
    pathContentType: string | undefined
}

export default function ArchiveButtons({ contentPage, pathContentType }: ArchiveButtonsProps) {
    return (
        <div className="App-body-archive-buttons">
            <Link className={`App-body-archive-buttons-button${contentPage.getPage() === contentPage.getTotalPages() ? '-disabled' : '' }`} to={`/archive/${pathContentType}?page=${getFirstPage(contentPage)}`} state={{ scrollToTop: true }}>&laquo;</Link>
            <Link className={`App-body-archive-buttons-button${contentPage.getPage() === contentPage.getTotalPages() ? '-disabled' : '' }`} to={`/archive/${pathContentType}?page=${getPreviousPage(contentPage)}`} state={{ scrollToTop: true }}>&#8249;</Link>
            <Link className={`App-body-archive-buttons-button${contentPage.getPage() === 1 ? '-disabled' : '' }`} to={`/archive/${pathContentType}?page=${getNextPage(contentPage)}`} state={{ scrollToTop: true }}>&#8250;</Link>
            <Link className={`App-body-archive-buttons-button${contentPage.getPage() === 1 ? '-disabled' : '' }`} to={`/archive/${pathContentType}?page=${getLastPage()}`} state={{ scrollToTop: true }}>&raquo;</Link>
        </div>
    )
}

function getFirstPage(contentPage: ContentPage) {
    return contentPage.getTotalPages()
}

function getPreviousPage(contentPage: ContentPage) {
    return contentPage.getPage() + 1
}

function getNextPage(contentPage: ContentPage) {
    return contentPage.getPage() - 1
}

function getLastPage() {
    return 1
}
