import { useParams, useSearchParams, useRouteError } from  'react-router-dom'

import PreviewImage from '../PreviewImage'

import ArchiveButtons from './ArchiveButtons'
import ContentData from '../../content/contentData'
import ContentPage, { PAGE_PICTURE_COUNT } from '../../content/contentPage'
import { data as comicData, slug as comicSlug } from '../../content/comics'
import { data as cortoData, slug as cortoSlug } from '../../content/cortos'
import { data as poitoStoryData, slug as poitoStorySlug } from '../../content/poitoStory'
import { data as sprintSummaryData, slug as sprintSummarySlug } from '../../content/sprintSummary'
import { data as videoData, slug as videoSlug } from '../../content/videos'

import { DEFAULT_IMAGE_URL, setMetaData } from '../Utils'

export function Archive() {
    const { pathContentType } = useParams()
    let [searchParams] = useSearchParams();
    const parsedPage = parsePage(searchParams.get("page"))
    const contentPage = getContentPage(pathContentType, parsedPage)

    setMetaData(
        '/content/' + pathContentType,
        DEFAULT_IMAGE_URL,
        contentPage.getTitle()
    )

    return (
        <main className="App-body-archive">
            <div className="App-body-archive-title">
                {contentPage.getTitle()}
            </div>
            <div className="App-body-content-splitter">
                <div className="App-body-content-splitter-inner"></div>
            </div>
            <ArchiveButtons contentPage={contentPage} pathContentType={pathContentType} />
            <div className="App-body-archive-images">
                {
                    contentPage.getContentData().map((contentDataEntry) =>
                        <PreviewImage key={contentDataEntry.getId()} contentData={contentDataEntry} />
                    )
                }
            </div>
            <ArchiveButtons contentPage={contentPage} pathContentType={pathContentType} />
        </main>
    )
}

export function ErrorArchive() {
    const error = useRouteError();
    console.error(error);

    return (
        <main className="App-body-content">
            <div className="App-body-content-title">
                Ha ocurrido un error
            </div>
            <div className="App-body-content-date">
                Vuelve a intentarlo
            </div>
            <img className="App-body-content-image" src="/pics/failed-load-image.webp" alt="error" />
        </main>
    )
}

function parsePage(queryPage: string | null): number | undefined {
    if (queryPage) {
        return Number(queryPage)
    }

    return undefined
}

function getContentPage(contentType?: string, page?: number): ContentPage {
    switch (contentType) {
        case comicSlug:
            return loadContentPage("Comics", comicData, page)
        case cortoSlug:
            return loadContentPage("Cortos", cortoData, page)
        case poitoStorySlug:
            return loadContentPage("Poito Story", poitoStoryData, page)
        case sprintSummarySlug:
            return loadContentPage("Sprint semanal", sprintSummaryData, page)
        case videoSlug:
            return loadContentPage("Videos", videoData, page)
        default:
            throw new TypeError(`Unknown content type ${contentType}`)
     }
}

function loadContentPage(title: string, contentData: ContentData[], page?: number): ContentPage {
    const totalPages = Math.ceil(contentData.length / PAGE_PICTURE_COUNT)

    // Use the latest one when the contentId parameter is bad or missing
    let correctedPage = 1
    if (page) {
        if (page > 1) {
            correctedPage = page
        }

        if(page > totalPages) {
            correctedPage = totalPages
        }
    }

    const offset = (correctedPage - 1) * PAGE_PICTURE_COUNT

    const contentDataSubset = contentData.slice(getLeftSliceBound(contentData, offset), contentData.length - offset).reverse()

    return new ContentPage(title, correctedPage, totalPages, contentDataSubset)
}

function getLeftSliceBound(contentData: ContentData[], offset: number) {
    if (contentData.length - offset - PAGE_PICTURE_COUNT < 0) {
        return 0
    }

    return contentData.length - offset - PAGE_PICTURE_COUNT;
}
