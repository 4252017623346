import ImageData from "./imageData"
import jsonData from "./data/sprintSummary.json"

export const slug = 'sprint_summary'

export class SprintSummaryData implements ImageData {
    private id: number
    private title: string
    private imageFileName: string
    private publishedDate: Date

    constructor(id: number, title: string, imageFileName: string, publishedDate: string) {
        this.id = id
        this.title = title
        this.imageFileName = imageFileName
        this.publishedDate = new Date(publishedDate)
    }
    
    getId(): number {
        return this.id
    }

    getTitle(): string {
        return this.title
    }

    getImagePath(): string {
        // Scaled down to 50% (never up) for the user experience (Sprint summary pictures are usually 1080px wide and high)
        return `/c_limit,w_540/f_auto/v1/${slug}/${this.imageFileName}`
    }

    getPreviewPath(): string {
        // First frame (if it is a gif)
        // Only scale down to 250 (not up)
        // Fill in any scaling gaps with a black BG
        // Black bar for text at the bottom
        // Apply text
        // To webp format for network savings
        return `/pg_1/c_limit,w_250/b_black,c_pad,h_250,w_250/l_black_bar,w_1.0,h_0.12,fl_relative,o_60,g_south/l_text:Montserrat_20_center:${this.title},co_white,x_10,y_7,g_south_west/f_auto/v1/${slug}/${this.imageFileName}`
    }

    getPublishedDate(): Date {
        return this.publishedDate
    }

    getSlug(): string {
        return slug
    }

    isFirst(): boolean {
        return this.id === 1
    }
 
    isLast(): boolean {
        return this.id === data.length
    }
}

function buildIntoObject(): SprintSummaryData[] {
    const sprintSummaryData = []
    
    for (var i = 0; i < jsonData.length; i++) {
        sprintSummaryData.push(new SprintSummaryData(jsonData[i].id, jsonData[i].title, jsonData[i].imageFileName, jsonData[i].publishedDate))
    }

    return sprintSummaryData
}

export const data: SprintSummaryData[] = buildIntoObject()
  