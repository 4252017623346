import ContentImage from './ContentImage'
import ContentVideo from './ContentVideo'

import ContentData from '../../content/contentData'
import { slug as comicSlug } from '../../content/comics'
import { slug as cortoSlug } from '../../content/cortos'
import { slug as poitoStorySlug } from '../../content/poitoStory'
import { slug as sprintSummarySlug } from '../../content/sprintSummary'
import { slug as videoSlug } from '../../content/videos'

interface ContentResourceProps {
    contentData: ContentData
}

export default function ContentResource({ contentData }: ContentResourceProps) {
    switch (contentData.getSlug()) {
        case comicSlug:
        case cortoSlug:
        case poitoStorySlug:
        case sprintSummarySlug:
            return (
                <ContentImage contentData={contentData} />
            )
        case videoSlug:
            return (
                <ContentVideo contentData={contentData} />
            )
        default: 
            throw new TypeError(`Unknown slug ${contentData.getSlug()}`)
     }
}
