export default function Footer() {
    return (
        <footer className="App-footer">
            <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
                <img alt="Licencia Creative Commons" className="App-footer-image" src="/pics/creative-commons.webp" />
            </a>
            <span className="App-footer-text App-footer-short-text">
                Bajo licencia <a className="App-footer-text-link" rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons</a>
            </span>
            <span className="App-footer-text App-footer-long-text">
                Esta obra está bajo una <a className="App-footer-text-link" rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional</a>
            </span>
        </footer>
    )
}
