import ImageData from "./imageData"
import jsonData from "./data/poitoStory.json"

export const slug = 'poito_story'

export class PoitoStoryData implements ImageData {
    private id: number
    private imageFileName: string
    private previewFileName: string
    private publishedDate: Date

    constructor(id: number,imageFileName: string, previewFileName: string, publishedDate: string) {
        this.id = id
        this.imageFileName = imageFileName
        this.previewFileName = previewFileName
        this.publishedDate = new Date(publishedDate)
    }
    
    getId(): number {
        return this.id
    }

    getTitle(): string {
        return `Poito Story #${this.id}`
    }

    getImagePath(): string {
        return `/f_auto/v1/${slug}/${this.imageFileName}`
    }

    getPreviewPath(): string {
        return `/f_auto/v1/${slug}/${this.previewFileName}`
        //return `l_black_bar,w_1.0,h_0.12,fl_relative,o_60,g_south/l_text:Montserrat_20_center:Poito Story %23${this.id},co_white,x_10,y_7,g_south_west/${this.previewFileName}`
    }

    getPublishedDate(): Date {
        return this.publishedDate
    }

    getSlug(): string {
        return slug
    }

    isFirst(): boolean {
        return this.id === 1
    }
 
    isLast(): boolean {
        return this.id === data.length
    }
}

function buildIntoObject(): PoitoStoryData[] {
    const poitoStoryData = []
    
    for (var i = 0; i < jsonData.length; i++) {
        poitoStoryData.push(new PoitoStoryData(jsonData[i].id, jsonData[i].imageFileName, jsonData[i].previewFileName, jsonData[i].publishedDate))
    }

    return poitoStoryData
}

export const data: PoitoStoryData[] = buildIntoObject()
  