import React from 'react'
import ReactDOM from 'react-dom/client'
import { Navigate } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import App from './App/App'
import { Content, ErrorContent } from './App/components/Content/Content'
import { Archive, ErrorArchive } from './App/components/Archive/Archive'

import './index.css'

//TODO: subdomains?
//TODO: error page https://reactrouter.com/en/main/start/tutorial#handling-not-found-errors and
//      errorElement + https://github.com/remix-run/react-router/blob/react-router%406.5.0/docs/start/tutorial.md
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Content />,
            },
            {
                path: "/content/:pathContentType/:pathContentId?",
                element: <Content />,
                errorElement: <ErrorContent />
            },
            {
                path: "/archive/:pathContentType",
                element: <Archive />,
                errorElement: <ErrorArchive />
            },
        ]
    },
    {
        path: "*",
        element: <Navigate to="/" replace />
    },
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)
