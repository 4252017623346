import ContentData from '../../content/contentData'

import { VideoData } from '../../content/videos'

interface ContentImageProps {
    contentData: ContentData
}

export default function ContentImage({ contentData }: ContentImageProps) {
    return (
        <div className="App-body-content-video iframe-width-helper">
            <iframe className="App-body-content-video-iframe" 
                src={`https://www.youtube.com/embed/${(contentData as VideoData).getVideoId()}`} 
                title={contentData.getTitle()} 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen></iframe>
        </div>
    )
}
