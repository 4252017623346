import { useState } from 'react'
import { Link } from  'react-router-dom'

import ContentData from '../content/contentData'

import { CLOUDINARY_URL, LOADING_PREVIEW_IMAGE_PATH, FAILED_LOAD_IMAGE_PATH } from './Utils'

interface PreviewImageProps {
    contentData: ContentData
}

export default function PreviewImage({ contentData }: PreviewImageProps) {
    const [isPreviewImageLoadedState, setPreviewImageLoaded] = useState({image: LOADING_PREVIEW_IMAGE_PATH, error: false})

    const onLoadEvent = () => {
        if (!isPreviewImageLoadedState.error) {
            setPreviewImageLoaded({image: CLOUDINARY_URL + contentData.getPreviewPath(), error: false})
        }
    }

    const onErrorEvent = () => setPreviewImageLoaded({image: FAILED_LOAD_IMAGE_PATH, error: true})

    return (
        <Link className="App-body-archive-images-link" to={`/content/${contentData.getSlug()}/${contentData.getId()}`} state={{ scrollToTop: true }}>
            <img className="App-body-archive-images-link-image" src={isPreviewImageLoadedState.image} alt={contentData.getTitle()}
                onLoad={onLoadEvent} onError={onErrorEvent} 
            />
        </Link>
    )
}
