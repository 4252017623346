import { Link } from  'react-router-dom'

import { getContentData } from './common'

import ContentData from '../../content/contentData'

interface ContentButtonsProps {
    contentData: ContentData
}

export default function ContentButtons({ contentData }: ContentButtonsProps) {
    return (
        <div className="App-body-content-buttons">
            <Link className={`App-body-content-buttons-button${contentData.isFirst() ? '-disabled' : '' }`} to={`/content/${contentData.getSlug()}/${getFirstId()}`} state={{ scrollToTop: true }}>&laquo;</Link>
            <Link className={`App-body-content-buttons-button${contentData.isFirst() ? '-disabled' : '' }`} to={`/content/${contentData.getSlug()}/${getPreviousId(contentData)}`} state={{ scrollToTop: true }}>&#8249;</Link>
            <Link className={`App-body-content-buttons-button${contentData.isLast() ? '-disabled' : '' }`} to={`/content/${contentData.getSlug()}/${getNextId(contentData)}`} state={{ scrollToTop: true }}>&#8250;</Link>
            <Link className={`App-body-content-buttons-button${contentData.isLast() ? '-disabled' : '' }`} to={`/content/${contentData.getSlug()}/${getLastId(contentData)}`} state={{ scrollToTop: true }}>&raquo;</Link>
        </div>
    )
}

function getFirstId() {
    return 1
}

function getPreviousId(contentDataState: ContentData) {
    return contentDataState.getId() - 1
}

function getNextId(contentDataState: ContentData) {
    return contentDataState.getId() + 1
}

function getLastId(contentDataState: ContentData) {
    const lastContentData = getContentData(contentDataState.getSlug())
    return lastContentData.getId()
}
