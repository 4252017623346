import ContentData from './contentData'
import jsonData from "./data/videos.json"

export const slug = 'videos'

export class VideoData implements ContentData {
    private id: number
    private title: string
    private videoId: string
    private previewFileName: string
    private publishedDate: Date

    constructor(id: number, title: string, videoId: string, previewFileName: string, publishedDate: string) {
        this.id = id
        this.title = title
        this.videoId = videoId
        this.previewFileName = previewFileName
        this.publishedDate = new Date(publishedDate)
    }

    getId(): number {
        return this.id
    }

    getTitle(): string {
        return `Video #${this.id} ${this.title}`
    }

    getVideoId(): string {
        return this.videoId
    }

    getPreviewPath(): string {
        return `/l_black_bar,w_1.0,h_0.12,fl_relative,o_60,g_south/l_text:Montserrat_20_center:Video %23${this.id},co_white,x_10,y_7,g_south_west/f_auto/v1/${slug}/${this.previewFileName}`
    }

    getPublishedDate(): Date {
        return this.publishedDate
    }

    getSlug(): string {
        return slug
    }

    isFirst(): boolean {
        return this.id === 1
    }
 
    isLast(): boolean {
        return this.id === data.length
    }
}

function buildIntoObject(): VideoData[] {
    const cortoData = []

    for (var i = 0; i < jsonData.length; i++) {
        cortoData.push(new VideoData(jsonData[i].id, jsonData[i].title, jsonData[i].videoId, jsonData[i].previewFileName, jsonData[i].publishedDate))
    }

    return cortoData
}

export const data: VideoData[] = buildIntoObject()
