import { Link, useParams } from  'react-router-dom'
import { useState } from 'react'

import { slug as comicSlug } from '../../content/comics'
import { slug as cortoSlug } from '../../content/cortos'
import { slug as poitoStorySlug } from '../../content/poitoStory'
import { slug as sprintSummarySlug } from '../../content/sprintSummary'
import { slug as videoSlug } from '../../content/videos'

import './Navbar.css'
import './WebNavbar.css'
import './MobileNavbar.css'

// Must match the values in the WebNavBar.css file
const MIN_WEBNAVBAR_HEIGHT = 50
const MAX_WEBNAVBAR_HEIGHT = 80
const MAX_WEB_SCROLL_HEIGHT = 150

const MIN_MOBILENAVBAR_WIDTH = 250

export default function Navbar() {    
    return (
        <div className="Navbar">
            <MobileNavbar />
            <WebNavbar />
        </div>
    )
}

function MobileNavbar() {
    const [mobileNavbarOpenState, setMobileNavbarOpen] = useState(false)

    const handleClick = () => {
        setMobileNavbarOpen(!mobileNavbarOpenState)
    }

    const { pathContentType } = useParams()
    const [lastSlugClickedState, setLastSlugClicked] = useState(pathContentType)

    return (
        <div className="MobileNavbar">
            <button className="MobileNavbar-MenuButton" type='button' onClick={handleClick}>
                <div className="MobileNavbar-MenuButton-Icon">
                    <div className="MobileNavbar-MenuButton-Icon-Bar"></div>
                    <div className="MobileNavbar-MenuButton-Icon-Bar"></div>
                    <div className="MobileNavbar-MenuButton-Icon-Bar"></div>
                </div>
            </button>

            <Link className="MobileNavbar-HomeLink" to={`/`} state={{ scrollToTop: true }}>
                <img className="MobileNavbar-HomeLink-Logo" src={process.env.PUBLIC_URL + '/pics/logo.webp'} alt="logo" />
            </Link>

            <div className="MobileNavbar-SideNav" style={{ marginLeft: (mobileNavbarOpenState ? 0 : -MIN_MOBILENAVBAR_WIDTH) }}>
                <MobileNavbarItem title='Sprints' slug={sprintSummarySlug} highlight={ pathContentType === sprintSummarySlug } lastSlugClickedState={lastSlugClickedState} setMobileNavbarOpen={setMobileNavbarOpen} setLastSlugClicked={setLastSlugClicked} />
                <MobileNavbarItem title='Poito Story' slug={poitoStorySlug} highlight={ pathContentType === poitoStorySlug } lastSlugClickedState={lastSlugClickedState} setMobileNavbarOpen={setMobileNavbarOpen} setLastSlugClicked={setLastSlugClicked} />
                <MobileNavbarItem title='Cortos' slug={cortoSlug} highlight={ pathContentType === cortoSlug } lastSlugClickedState={lastSlugClickedState} setMobileNavbarOpen={setMobileNavbarOpen} setLastSlugClicked={setLastSlugClicked} />
                <MobileNavbarItem title='Comics' slug={comicSlug} highlight={ pathContentType === comicSlug } lastSlugClickedState={lastSlugClickedState} setMobileNavbarOpen={setMobileNavbarOpen} setLastSlugClicked={setLastSlugClicked} />
                <OtherMobileNavbarItem highlight={ pathContentType === videoSlug } lastSlugClickedState={lastSlugClickedState} setMobileNavbarOpen={setMobileNavbarOpen} setLastSlugClicked={setLastSlugClicked} />
            </div>
            <button className="MobileNavbar-DarkOverlay" type='button' onClick={handleClick} style={{ marginLeft: (mobileNavbarOpenState ? 0 : '-100vw') }} />
        </div>
    )
}

interface MobileNavbarItemProps {
    title: string
    slug: string
    highlight: boolean
    lastSlugClickedState: string | undefined
    setMobileNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLastSlugClicked: React.Dispatch<React.SetStateAction<string | undefined>>
}

function MobileNavbarItem({ title, slug, highlight, lastSlugClickedState, setMobileNavbarOpen, setLastSlugClicked }: MobileNavbarItemProps) {
    const handleItemClick = () => {
        if (lastSlugClickedState === slug) {
            setLastSlugClicked(undefined)
        } else {
            setLastSlugClicked(slug)
        }
    }

    const handleSubitemClick = () => {
        setMobileNavbarOpen(false)
    }

    return (
        <div className={`MobileNavbar-SideNav-Item${lastSlugClickedState === slug ? ' Clicked' : '' }`}>
            { highlight && lastSlugClickedState === slug && <div className='MobileNavbar-Item-Button-Underline Clicked'></div> }
            { highlight && lastSlugClickedState !== slug && <div className='MobileNavbar-Item-Button-Underline'></div> }
            <button className={`MobileNavbar-SideNav-Item-Button${highlight ? ' Highlight' : '' }`} type='button' onClick={handleItemClick}>
                {title} <span className=' MobileNavbar-SideNav-Item-Button-Arrow'>▼</span>
            </button>
            <div className='MobileNavbar-SideNav-Item-Subitem'>
                <Link className="MobileNavbar-SideNav-Item-Subitem-Link" to={`/content/${slug}`} state={{ scrollToTop: true }} onClick={handleSubitemClick}>Mas reciente</Link>
                <Link className="MobileNavbar-SideNav-Item-Subitem-Link" to={`/archive/${slug}`} state={{ scrollToTop: true }} onClick={handleSubitemClick}>Listado</Link>
            </div>
        </div>
    )
}

interface OtherMobileNavbarItemProps {
    highlight: boolean
    lastSlugClickedState: string | undefined
    setMobileNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLastSlugClicked: React.Dispatch<React.SetStateAction<string | undefined>>
}

function OtherMobileNavbarItem({ highlight, lastSlugClickedState, setMobileNavbarOpen, setLastSlugClicked }: OtherMobileNavbarItemProps) {
    const handleItemClick = () => {
        if (lastSlugClickedState === videoSlug) {
            setLastSlugClicked(undefined)
        } else {
            setLastSlugClicked(videoSlug)
        }
    }

    const handleSubitemClick = () => {
        setMobileNavbarOpen(false)
    }

    return (
        <div className={`MobileNavbar-SideNav-Item${lastSlugClickedState === videoSlug ? ' Clicked' : '' }`}>
            { highlight && lastSlugClickedState === videoSlug && <div className='MobileNavbar-Item-Button-Underline Clicked'></div> }
            { highlight && lastSlugClickedState !== videoSlug && <div className='MobileNavbar-Item-Button-Underline'></div> }
            <button className={`MobileNavbar-SideNav-Item-Button${highlight ? ' Highlight' : '' }`} type='button' onClick={handleItemClick}>
                Mas contenido <span className=' MobileNavbar-SideNav-Item-Button-Arrow'>▼</span>
            </button>
            <div className='MobileNavbar-SideNav-Item-Subitem'>
                <Link className="MobileNavbar-SideNav-Item-Subitem-Link" to={`/content/${videoSlug}`} state={{ scrollToTop: true }} onClick={handleSubitemClick}>Video mas reciente</Link>
                <Link className="MobileNavbar-SideNav-Item-Subitem-Link" to={`/archive/${videoSlug}`} state={{ scrollToTop: true }} onClick={handleSubitemClick}>Listado videos</Link>
            </div>
        </div>
    )
}

function WebNavbar() {
    const [webNavBarHeightState, setWebNavBarHeight] = useState(90)

    const handleScroll = (e: Event) => {
        const scrollTop = window.scrollY

        const scrollMargin = MAX_WEBNAVBAR_HEIGHT - MIN_WEBNAVBAR_HEIGHT
        const variableScrollHeight = (scrollMargin / MAX_WEB_SCROLL_HEIGHT) * scrollTop
        const variableHeight = MAX_WEBNAVBAR_HEIGHT - variableScrollHeight

        const WebNavBarHeight = Math.max(MIN_WEBNAVBAR_HEIGHT, variableHeight)

        setWebNavBarHeight(WebNavBarHeight)
    }

    window.addEventListener('scroll', handleScroll)

    const { pathContentType } = useParams()
    
    return (
        <div className="WebNavbar" style={{ height: webNavBarHeightState }}>
            <Link className="WebNavbar-HomeLink" to={`/`} state={{ scrollToTop: true }}>
                <img className="WebNavbar-HomeLink-Logo" src={process.env.PUBLIC_URL + '/pics/logo.webp'} alt="logo" />
            </Link>
            <WebNavbarItem title='Sprints' slug={sprintSummarySlug} highlight={ pathContentType === sprintSummarySlug } />
            <WebNavbarItem title='Poito Story' slug={poitoStorySlug} highlight={ pathContentType === poitoStorySlug } />
            <WebNavbarItem title='Cortos' slug={cortoSlug} highlight={ pathContentType === cortoSlug } />
            <WebNavbarItem title='Comics' slug={comicSlug} highlight={ pathContentType === comicSlug } />
            <OtherWebNavbarItem highlight={ pathContentType === videoSlug } />
        </div>
    )
}

interface WebNavbarItemProps {
    title: string
    slug: string
    highlight: boolean
}

function WebNavbarItem({ title, slug, highlight }: WebNavbarItemProps) {
    return (
        <div className={`WebNavbar-Item${highlight ? ' Highlight' : '' }`}>
            <button className='WebNavbar-Item-Button' type='button'>
                {title} <span className=' WebNavbar-Item-Button-Arrow'>▼</span>
            </button>
            { highlight && <div className='WebNavbar-Item-Button-Underline'></div> }
            <div className='WebNavbar-Item-Subitem'>
                <Link className="WebNavbar-Item-Subitem-Link" to={`/content/${slug}`} state={{ scrollToTop: true }}>Mas reciente</Link>
                <Link className="WebNavbar-Item-Subitem-Link" to={`/archive/${slug}`} state={{ scrollToTop: true }}>Listado</Link>
            </div>
        </div>
    )
}

interface OtherWebNavbarItemProps {
    highlight: boolean
}

function OtherWebNavbarItem({ highlight }: OtherWebNavbarItemProps) {
    return (
        <div className={`WebNavbar-Item${highlight ? ' Highlight' : '' }`}>
            <button className='WebNavbar-Item-Button' type='button'>
                Mas Contenido <span className=' WebNavbar-Item-Button-Arrow'>▼</span>
            </button>
            { highlight && <div className='WebNavbar-Item-Button-Underline'></div> }
            <div className='WebNavbar-Item-Subitem'>
                <Link className="WebNavbar-Item-Subitem-Link" to={`/content/${videoSlug}`} state={{ scrollToTop: true }}>Video mas reciente</Link>
                <Link className="WebNavbar-Item-Subitem-Link" to={`/archive/${videoSlug}`} state={{ scrollToTop: true }}>Listado videos</Link>
            </div>
        </div>
    )
}
