import { useParams, useRouteError } from  'react-router-dom'

import { getContentData } from './common'

import ContentButtons from './ContentButtons'
import ContentResource from './ContentResource'

import { slug as videoSlug } from '../../content/videos'

import { BASE_URL, CLOUDINARY_URL, setMetaData } from '../Utils'

export function Content() {
    const { pathContentType, pathContentId } = useParams()

    const parsedContentId = parseContentId(pathContentId)
    const contentData = getContentData(pathContentType, parsedContentId)

    if (pathContentType) {
        setMetaData(
            BASE_URL + '/content/' + pathContentType + '/' + contentData.getId(),
            CLOUDINARY_URL + contentData.getPreviewPath(),
            contentData.getTitle()
        )
    } else {
        setMetaData(
            '',
            BASE_URL,
            'CAVPOLLOS Comics'
        )
    }

    return (
        <main className={`App-body-content${pathContentType === videoSlug ? ' iframe-width-helper' : '' }`}>
            <div className="App-body-content-title">
                {contentData.getTitle()}
            </div>
            <div className="App-body-content-date">
                {contentData.getPublishedDate().toDateString()}
            </div>
            <div className="App-body-content-splitter">
                <div className="App-body-content-splitter-inner"></div>
            </div>
            <ContentButtons contentData={contentData} />
            <ContentResource key={contentData.getId()} contentData={contentData} />
            <ContentButtons contentData={contentData} />
        </main>
    )
}


export function ErrorContent() {
    const error = useRouteError();
    console.error(error);

    return (
        <main className="App-body-content">
            <div className="App-body-content-title">
                Ha ocurrido un error
            </div>
            <div className="App-body-content-date">
                Vuelve a intentarlo
            </div>
            <img className="App-body-content-image" src="/pics/failed-load-image.webp" alt="error" />
        </main>
    )
}

function parseContentId(pathContentId?: string): number | undefined {
    if (pathContentId) {
        return Number(pathContentId)
    }

    return undefined
}
