import ContentData from '../../content/contentData'
import { data as comicData, slug as comicSlug } from '../../content/comics'
import { data as cortoData, slug as cortoSlug } from '../../content/cortos'
import { data as poitoStoryData, slug as poitoStorySlug } from '../../content/poitoStory'
import { data as sprintSummaryData, slug as sprintSummarySlug } from '../../content/sprintSummary'
import { data as videoData, slug as videoSlug } from '../../content/videos'

export function getContentData(contentType?: string, contentId?: number): ContentData {
    switch (contentType) {
        case comicSlug:
            return loadContent(comicData, contentId)
        case cortoSlug:
            return loadContent(cortoData, contentId)
        case poitoStorySlug:
            return loadContent(poitoStoryData, contentId)
        case sprintSummarySlug:
            return loadContent(sprintSummaryData, contentId)
        case videoSlug:
            return loadContent(videoData, contentId)
        default: 
            return loadLatestContent()
     }
}

function loadLatestContent(): ContentData {
    const allContentTypesData: ContentData[][] = [comicData, cortoData, videoData, poitoStoryData, sprintSummaryData]

    const latestContentDataByType: ContentData[] = allContentTypesData.map(contentTypeData => contentTypeData.at(-1)!)

    const latestContentType: ContentData = latestContentDataByType.sort(
        (a: ContentData, b: ContentData) => {
            if (a.getPublishedDate() < b.getPublishedDate()) {
                return 1
            }
        
            if (a.getPublishedDate() > b.getPublishedDate()) {
                return -1
            }
        
            return 0
        }
    )[0]

    return getContentData(latestContentType.getSlug())
}

function loadContent(contentData: ContentData[], contentId?: number): ContentData {
    // Remember the ids start at 1, while the array starts at 0
    if (contentId && contentId >= 1 && contentId <= contentData.length) {
        return contentData[contentId - 1]
    } else {
        // Use the latest one when the contentId parameter is bad or missing
        return contentData.slice(-1)[0]
    }
}
