import { useState } from 'react'

import ContentData from '../../content/contentData'
import ImageData from '../../content/imageData'


import { CLOUDINARY_URL, LOADING_PREVIEW_IMAGE_PATH, FAILED_LOAD_IMAGE_PATH } from '../Utils'

interface ContentImageProps {
    contentData: ContentData
}

export default function ContentImage({ contentData }: ContentImageProps) {
    const [isContentImageLoadedState, setContentImageLoaded] = useState({image: LOADING_PREVIEW_IMAGE_PATH, error: false})
 
    const onLoadEvent = () => {
        if (!isContentImageLoadedState.error) {
            setContentImageLoaded({image: CLOUDINARY_URL + (contentData as ImageData).getImagePath(), error: false})
        }
    }

    const onErrorEvent = () => setContentImageLoaded({image: FAILED_LOAD_IMAGE_PATH, error: true})

    return (
        <img className="App-body-content-image" 
            src={isContentImageLoadedState.image} alt={contentData.getTitle()} 
            onLoad={onLoadEvent} onError={onErrorEvent} />
    )
}
