export const BASE_URL = 'https://cavpollos.com'
export const CLOUDINARY_URL = 'https://res.cloudinary.com/hmjm9tfyg/image/upload'
export const DEFAULT_IMAGE_URL = 'https://cavpollos.com/pics/poito_128.webp'
export const LOADING_PREVIEW_IMAGE_PATH = '/pics/loading-preview-image.webp'
export const FAILED_LOAD_IMAGE_PATH = '/pics/failed-load-image.webp'

export const setMetaData = (contentPath: string, imageUrl: string, description: string) =>{
    const metaTags = document.getElementsByTagName("META")
    for (let index = 0, len = metaTags.length; index < len; ++index) {
        const metaTag = metaTags[index];
        const attribute = metaTag.getAttribute('property');
        switch (attribute) {
            case 'og:image':
                metaTag.setAttribute('content', imageUrl)
                break
            case 'og:url':
                metaTag.setAttribute('content', BASE_URL + contentPath)
                break
            case 'og:description':
                metaTag.setAttribute('content', description)
                break
        }
    }
}
