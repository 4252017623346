import ContentData from './contentData'

export const PAGE_PICTURE_COUNT = 12

export default class ContentPage {
    private title: string
    private page: number
    private totalPages: number
    private contentData: ContentData[]

    constructor(title: string, page: number, totalPages: number, contentData: ContentData[]) {
        this.title = title
        this.page = page
        this.totalPages = totalPages
        this.contentData = contentData
    }
    
    getTitle(): string {
        return this.title
    }
    
    getPage(): number {
        return this.page
    }

    getContentData(): ContentData[] {
        return this.contentData
    }

    getTotalPages(): number {
        return this.totalPages
    }
}
