import { Outlet, useLocation } from 'react-router-dom'

import Navbar from './components/NavBar/Navbar'
import Header from './components/Header'
import Footer from './components/Footer'

import './App.css'

//TODO: Set the page's preview in case of it getting shared
export default function App() {
    const { state } = useLocation()
    if (state && state.scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        state.scrollToTop = false
    }

    return (
        <div className="App">
            <div className="App-bg" style={{ backgroundImage: `url('/pics/bg_pattern.webp')` }}></div>
            <Navbar />
            <Header />
            <div className="App-body">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}
